import mixpanel from "mixpanel-browser";

type MixpanelEvent = {
  name: string;
  properties?: Record<string, any>;
};

export const trackEvent = ({ name, properties = {} }: MixpanelEvent) => {
  // Don't run analytics in storybook
  if (process.env.STORYBOOK) {
    return;
  }
  if (mixpanel && mixpanel.track) {
    mixpanel.track(name, properties);
  }
};

export const trackPageView = (
  page: string,
  properties?: Record<string, any>
) => {
  // Don't run analytics in storybook
  if (process.env.STORYBOOK) {
    return;
  }
  if (mixpanel && mixpanel.track) {
    mixpanel.track(`Page View: ${page}`, properties);
  }
};
